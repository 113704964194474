import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const PrivacyPage = () => {
    return (
        <Layout scroll={false} headerMode="no-quote" noCookies>
            <SEO title="Privacy Policy" />
            <article className="content">
                <header>
                    <h1 className="text-center">Cookie Policy</h1>
                    <small>
                        Last revised: February 13, 2020
                </small>
                    <hr />
                    <p>
                        This Cookie Policy explains how cookies and similar technologies (collectively,
                    “<strong>Cookie(s)</strong>”) are used when you visit our Site.
                            A “Site” includes our websites, emails, and other applications owned and operated by
                            The Removal Man (“our”, or “us”)
                            as well as any other services that display this Cookie Policy.
                            This policy explains what these technologies are and why they are used, as well as your
                            right to control their use.
                </p>
                    <p>
                        <strong>Note: </strong>
                        By clicking “Accept” on the Cookie Disclaimer
                        or by simply continue using the website, you give us consent to use cookies on this website.
                </p>
                    <p>
                        We may change this Cookie Policy at any time.  Please take a look at the “LAST REVISED”
                        date at the top of this page to see when this Cookie Policy was last revised. Any change
                        in this Cookie Policy will become effective when we make the revised Cookie Policy
                        available on or through the Site.
                </p>
                    <p>
                        If you have any question, please get in touch with us.
                </p>
                </header>


                <section >
                    <h2>1. What Is A Cookie?</h2>
                    <p>
                        A cookie is a small text file (often including a unique identifier), that is sent to a
                        user’s browser from a website's computers and stored on a user’s computer's hard drive
                    or on a tablet or mobile device (collectively, “<strong>Computer</strong>”). A Cookie stores a small
                            amount of data on your Computer about your visit to the Site.
                </p>
                    <p>
                        You may learn more about Cookies <a href="https://cookiesandyou.com/" target="_blank" rel="nofollow noopener noreferrer">here</a>.
                    </p>
                    <p>
                        We place and access Cookies on your Computer; these Cookies are known as “first-party
                        Cookies.” Cookies may also be placed and accessed by some of our third-party partners,
                        which are known as “third-party Cookies” and are described below. Some Cookies are
                        "session Cookies," which means that they are temporary Cookies that are only stored on
                        your device while you are visiting our Site. Other Cookies are "persistent Cookies,"
                        which means that they are stored on your device for a period of time after you leave our
                        Site.
                    </p>

                </section>

                <section>
                    <h2>2. Which Cookies Do We Use?</h2>
                    <p>
                        Below we list the different types of Cookies that are used on the Site that you are visiting.
                    </p>

                    {/* <section>
                    <h3>Necessary Cookies</h3>
                    <p>
                        Necessary Cookies enable you to navigate the Site and to use its services and
                        features. Without these necessary Cookies, the Site will not perform as smoothly for
                        you as we would like it to and we may not be able to provide the Site or certain
                        services or features.
                        </p>
                    <table>
                        <thead>
                            <tr style={{ height: "23px" }}>
                                <th style={{ height: "23px" }}>Cookie</th>
                                <th style={{ height: "23px" }}>Description</th>
                                <th style={{ height: "23px" }}>Duration</th>
                                <th style={{ height: "23px" }}>1st/3rd party</th>
                                <th style={{ height: "23px" }}>Privacy policy</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr style={{ height: "23px" }}>
                                <td style={{ height: "23px" }}>n_fdly_cs</td>
                                <td style={{ height: "23px" }}>Cookie to remember Cookie preferences.</td>
                                <td style={{ height: "23px" }}>1 year</td>
                                <td style={{ height: "23px" }}>1st</td>
                                <td style={{ height: "23px" }}><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                            <tr style={{ height: "23px" }}>
                                <td style={{ height: "23px" }}>n_fdly_ar</td>
                                <td style={{ height: "23px" }}>Authentication cookie, required to complete Google Firebase authentication flow. Will use Local Storage if available</td>
                                <td style={{ height: "23px" }}>undef</td>
                                <td style={{ height: "23px" }}>1st</td>
                                <td style={{ height: "23px" }}><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                           
                            <tr style={{ height: "23px" }}>
                                <td style={{ height: "23px" }}>n_fdly_mtrc</td>
                                <td style={{ height: "23px" }}>Cookie to remember the selected recipe ingredients measurement system. Will use Local Storage if available</td>
                                <td style={{ height: "23px" }}>undef</td>
                                <td style={{ height: "23px" }}>1st</td>
                                <td style={{ height: "23px" }}><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                            <tr style={{ height: "23px" }}>
                                <td style={{ height: "23px" }}>n_fdly_nsx</td>
                                <td style={{ height: "23px" }}>Cookie to remember the selected recipe nutrition 'gender', for the purpose of choosing between a 2k or 2.5k calories diet. Will use Local Storage if available</td>
                                <td style={{ height: "23px" }}>undef</td>
                                <td style={{ height: "23px" }}>1st</td>
                                <td style={{ height: "23px" }}><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                        </tbody>
                    </table>
                </section> */}
                    {/* 
                <section>
                    <h3>Functional Cookies</h3>
                    <p>
                        Functional Cookies collect information about your choices and preferences, and allow
                        us to remember language or other local settings and customize the Site accordingly.
                        </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Cookie</th>
                                <th>Description</th>
                                <th>Duration</th>
                                <th>1st/3rd party</th>
                                <th>Privacy policy</th>
                            </tr>
                        </thead>
                        <tbody>
                         
                            <tr>
                                <td>f_fdly_sub</td>
                                <td>Remember Newsletter subscription</td>
                                <td>undef</td>
                                <td>1st</td>
                                <td><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                            <tr>
                                <td>f_fdly_wpsub</td>
                                <td>Remember Webpush subscription</td>
                                <td>undef</td>
                                <td>1st</td>
                                <td><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                            <tr>
                                <td>f_fdly_sub_b</td>
                                <td>Remember Newsletter subscription banner dismissal</td>
                                <td>5 days</td>
                                <td>1st</td>
                                <td><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                            <tr>
                                <td>f_fdly_wpsub_b</td>
                                <td>Remember Webpush subscription banner dismissal</td>
                                <td>5 days</td>
                                <td>1st</td>
                                <td><Link to="/usage-policies/privacy-policy">go to site</Link></td>
                            </tr>
                        </tbody>
                    </table>
                </section> */}

                    <section>
                        <h3>Performance Cookies</h3>
                        <p>
                            Analytics Cookies collect information about your use of the Site, and enable us to
                            improve the way it works. For example, Analytics Cookies show us which are the most
                            frequently visited pages on the Site, help us record any difficulties you have with the
                            Site, and show us whether our advertising is effective or not. Analytics Cookies allow
                            us to see the overall patterns of usage on the Site, rather than the usage of a single
                            person. We use information from Analytics Cookies to analyze the Site traffic, but we do
                            not examine this information for individually identifying information.
                        </p>
                        <div className="scroll-container">

                            <table>
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Description</th>
                                        <th>Duration</th>
                                        <th>1st/3rd party</th>
                                        <th>Privacy policy</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>__utma</td><td>Google Analytics</td><td>2 years</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__utmt</td><td>Google Analytics</td><td>10 minutes</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__utmc</td><td>Google Analytics</td><td>session</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__utmv</td><td>Google Analytics</td><td>2 years</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__utmz</td><td>Google Analytics</td><td>6 months</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>_ga</td><td>Google Analytics</td><td>2 years</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>_gid</td><td>Google Analytics</td><td>24 hours</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>_gat (or _dc_gtm_*)</td><td>Google Analytics</td><td>1 minute</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    {/* <tr><td>_hjClosedSurveyInvites</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjDonePolls</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjMinimizedPolls</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjDoneTestersWidgets</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjMinimizedTestersWidgets</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjIncludedInSample</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                            <tr><td>_hjShownFeedbackMessage</td><td>Hotjar cookie</td><td>365 days</td><td>3rd</td><td><a href="https://www.hotjar.com/legal/policies/cookie-information" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr> */}
                                </tbody>
                            </table>
                        </div>

                    </section>

                    <section>
                        <h3>Targeting Cookies</h3>
                        <p>
                            Advertising Cookies are set to display targeted promotions or advertisements based
                            upon your interests on the Site or to manage our advertising. These Cookies collect
                            information about your activities on this Site and other websites to provide you
                            targeted advertising.
                        </p>
                        <div className="scroll-container">

                            <table>
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Description</th>
                                        <th>Duration</th>
                                        <th>1st/3rd party</th>
                                        <th>Privacy policy</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>ad-id</td><td>Amazon</td><td>6 months, 3 weeks</td><td>3rd</td><td><a href="http://aws.amazon.com/privacy/" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>ad-privacy</td><td>Amazon</td><td>6 months, 3 weeks</td><td>3rd</td><td><a href="http://aws.amazon.com/privacy/" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>NID</td><td>Google Advertising</td><td>6 months</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>SID</td><td>Google Advertising</td><td>2 years</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>IDE</td><td>Google Advertising</td><td>1 year</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>ANID</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>DSID</td><td>Google Advertising</td><td>8 days</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>FLC</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>AID</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>TAID</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__gads</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>__gac</td><td>Google Advertising</td><td>undef</td><td>3rd</td><td><a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>act</td><td>Facebook</td><td>undef</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>c_user</td><td>Facebook</td><td>undef</td><td>3 months</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>datr</td><td>Facebook</td><td>undef</td><td>1 year 11 months</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>dpr</td><td>Facebook</td><td>undef</td><td>1 week</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>fr</td><td>Facebook</td><td>undef</td><td>3 months</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>m_pixel_ratio</td><td>Facebook</td><td>undef</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>pl</td><td>Facebook</td><td>2 months</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>presence</td><td>Facebook</td><td>undef</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>sb</td><td>Facebook</td><td>1 yer 11 months</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>spin</td><td>Facebook</td><td>1 day</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>wd</td><td>Facebook</td><td>1 week</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    <tr><td>xs</td><td>Facebook</td><td>3 months</td><td>3rd</td><td><a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow noopener noreferrer">go to site</a></td></tr>
                                    {/* <tr><td>ANONCHK</td><td>Bing is an internet search engine operated by Microsoft.</td><td>session</td><td>3rd</td><td><a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" rel="nofollow noopener noreferrer">bing.com - Pixel privacy policy</a></td></tr> */}
                                </tbody>
                            </table>
                        </div>

                    </section>
                </section>

                <section>
                    <h2>3. How Do I Manage Cookies?</h2>
                    <p>
                        You can change your Cookie settings <a href="#preferences">from here</a>
                    </p>

                    <p>
                        In addition to the options provided above, you may refuse or accept Cookies from the
                        Site or any other website at any time by activating settings on your browser. Most
                        browsers automatically accept Cookies, but you can usually modify your browser setting
                        to decline Cookies if you prefer. If you choose to decline Cookies, you may not be able
                        to sign in or use other interactive features of our Site that depend on Cookies.
                        Information about the procedure to follow in order to enable or disable Cookies can be
                        found at:
                    </p>
                    <ul>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="nofollow noopener noreferrer">Chrome</a></li>
                        <li><a href="https://support.apple.com/kb/PH19255?locale=en_US" target="_blank" rel="nofollow noopener noreferrer">Safari</a></li>
                        <li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="nofollow noopener noreferrer">Safari Mobile (iPhone and iPads)</a></li>
                        <li><a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank" rel="nofollow noopener noreferrer">Firefox</a></li>
                        <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="nofollow noopener noreferrer">Microsoft Edge</a></li>
                    </ul>
                    <p>
                        For more information about other commonly used browsers, please refer to <br />
                        <a href="http://www.allaboutcookies.org/manage-cookies/" target="_blank" rel="nofollow noopener noreferrer">http://www.allaboutcookies.org/manage-cookies/</a>.
                    </p>
                    <p>
                        Please be aware that if Cookies are disabled, not all features of the Site may operate as
                        intended.
                    </p>
                    <p>
                        To opt-out of participating in Google Analytics data follow the instructions: <br />
                        <a href="https://tools.google.com/dlpage/gaoptout" id="choices-ga" target="_blank" rel="nofollow noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>.
                    </p>
                    <p>
                        If the Site has ads, some of the network advertisers, ad agencies and other vendors we work with to serve ads
                        on our Site, third-party websites and across the internet, or analytics providers that
                        provide us with information regarding the use of the Site and effectiveness of ads are
                        members of the Network Advertising Initiatives, the Digital Advertising Alliance
                        Self-Regulatory Program for Online Behavioural Advertising and/or the European
                        Interactive Digital Advertising Alliance. To opt out from receiving interest-based
                        advertising from some or all of the participating companies, please visit
                        <a href="http://www.aboutads.info/choices/" id="choices-about" target="_blank" rel="nofollow noopener noreferrer"> http://www.aboutads.info/choices/ </a> and
                        <a href="http://www.youronlinechoices.eu/" id="choices-your" target="_blank" rel="nofollow noopener noreferrer"> http://www.youronlinechoices.eu/</a>.
Please note opting out through these mechanisms does not mean you will no longer be
served advertising. You will still receive other types of online advertising from
participating companies and any type of advertising from non-participating companies,
and the web sites you visit may still collect information for other purposes.
                    </p>
                    Below we will provide some specific ways for you to opt-out of certain programs:
                    <ul>
                        <li>
                            To opt-out of personalized Amazon Ads you can visit <a href="https://www.amazon.com/adprefs" target="_blank" rel="nofollow noopener noreferrer">https://www.amazon.com/adprefs</a>.
                            Note that if you are in a different country than US, you will have to go to the url for your own country (i.e. '.co.uk' for UK visitors)
                        </li>
                        <li>
                            To opt-out of personalized Google ads you can visit <a href="https://adssettings.google.com/authenticated" target="_blank" rel="nofollow noopener noreferrer">https://adssettings.google.co.uk/authenticated</a>.
                            Note that if you are in a different country than US, you will have to go to the url for your own country (i.e. '.co.uk' for UK visitors)
                        </li>
                        <li>
                            To adjust how Facebook uses targeting technology based on your activity, you can visit <a href="https://www.facebook.com/help/568137493302217" target="_blank" rel="nofollow noopener noreferrer">https://www.facebook.com/help/568137493302217</a>
                        </li>
                    </ul>

                </section>
            </article>
        </Layout>
    )
}

export default PrivacyPage
